<template>
  <div id="materialRequisition">
    <el-dialog
      :title="materialRequisitionFormTitle"
      width="1200px"
      :visible.sync="materialRequisitionDialogVisible"
      :close-on-click-modal="false"
      @close="materialRequisitionDialogClose"
    >
      <el-form
        ref="materialRequisitionFormRef"
        :model="materialRequisitionForm"
        :rules="materialRequisitionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请部门" prop="applyDept">
              <el-select
                v-model="materialRequisitionForm.applyDept"
                placeholder="请选择申请部门"
                clearable
                :disabled="materialRequisitionFormTitle !== '新增领料单'
                  && materialRequisitionFormTitle !== '修改领料单'
                  && materialRequisitionFormTitle !== '领料单详情'"
                @change="deptChange"
              >
                <el-option
                  v-for="item in ['生产设备部', '质量部', '采购部', '仓储部', '销售部']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="materialRequisitionForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="materialRequisitionFormTitle !== '新增领料单'
                  && materialRequisitionFormTitle !== '修改领料单'
                  && materialRequisitionFormTitle !== '领料单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期" prop="applyDate">
              <el-date-picker
                v-model="materialRequisitionForm.applyDate"
                placeholder="请选择申请日期"
                value-format="yyyy-MM-dd"
                :disabled="materialRequisitionFormTitle !== '新增领料单'
                  && materialRequisitionFormTitle !== '修改领料单'
                  && materialRequisitionFormTitle !== '领料单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="materialRequisitionFormTitle === '新增领料单'
              || materialRequisitionFormTitle === '修改领料单'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="materialNo" title="物料编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择物料"
                  clearable
                  filterable
                  @change="materialPurchaseStockChange(row)"
                >
                  <el-option
                    v-for="item in materialPurchaseStockList"
                    :key="item.id"
                    :label="item.materialNo"
                    :value="item.materialNo"
                  >
                    <span style="float: left">{{ item.materialNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.productName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="materialName"
              title="物料名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="planQuantity"
              title="计划领用数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="situationExplain"
              title="领料情况说明"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="materialRequisitionFormTitle === '新增领料单'
                || materialRequisitionFormTitle === '修改领料单'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="materialRequisitionForm.reviewerResult"
                :disabled="materialRequisitionFormTitle !== '审核领料单'
                  && materialRequisitionFormTitle !== '领料单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果" prop="confirmerResult">
              <el-radio-group
                v-model="materialRequisitionForm.confirmerResult"
                :disabled="materialRequisitionFormTitle !== '确认领料单'
                  && materialRequisitionFormTitle !== '领料单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="materialRequisitionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialRequisitionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.serialNumber" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['MATERIAL_REQUISITION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialRequisitionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column prop="applyDept" label="申请部门" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applyDate">{{ scope.row.applyDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editorDate">{{ scope.row.editorDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmer" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerDate">{{ scope.row.confirmerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确认结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerResult === 1">通过</span>
          <span v-if="scope.row.confirmerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['MATERIAL_REQUISITION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['MATERIAL_REQUISITION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['MATERIAL_REQUISITION_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="checkPermission(['MATERIAL_REQUISITION_CONFIRM']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确认
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialRequisitionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialRequisition,
  deleteMaterialRequisition,
  updateMaterialRequisition,
  selectMaterialRequisitionInfo,
  selectMaterialRequisitionList,
  reviewMaterialRequisition,
  confirmMaterialRequisition
} from '@/api/universal/materialRequisition'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import moment from 'moment'

export default {
  data () {
    return {
      materialRequisitionDialogVisible: false,
      materialRequisitionFormTitle: '',
      materialRequisitionForm: {
        id: '',
        serialNumber: '',
        applyDept: '',
        applyDate: '',
        reviewerResult: '',
        confirmerResult: '',
        detailJson: '',
        taskId: ''
      },
      materialRequisitionFormRules: {
        serialNumber: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      materialRequisitionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: ''
      },
      detailList: [],
      materialPurchaseStockList: []
    }
  },
  created () {
    selectMaterialRequisitionList(this.searchForm).then(res => {
      this.materialRequisitionPage = res
    })
    selectMaterialPurchaseStockList({ type: 1 }).then(res => {
      this.materialPurchaseStockList = res.list
    })
  },
  methods: {
    materialRequisitionDialogClose () {
      this.$refs.materialRequisitionFormRef.resetFields()
      this.detailList = []
    },
    materialRequisitionFormSubmit () {
      if (this.materialRequisitionFormTitle === '领料单详情') {
        this.materialRequisitionDialogVisible = false
        return
      }
      this.$refs.materialRequisitionFormRef.validate(async valid => {
        if (valid) {
          if (this.materialRequisitionFormTitle === '新增领料单') {
            this.materialRequisitionForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addMaterialRequisition(this.materialRequisitionForm)
          } else if (this.materialRequisitionFormTitle === '修改领料单') {
            this.materialRequisitionForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateMaterialRequisition(this.materialRequisitionForm)
          } else if (this.materialRequisitionFormTitle === '审核领料单') {
            await reviewMaterialRequisition(this.materialRequisitionForm)
          } else if (this.materialRequisitionFormTitle === '确认领料单') {
            await confirmMaterialRequisition(this.materialRequisitionForm)
          }
          this.materialRequisitionPage = await selectMaterialRequisitionList(this.searchForm)
          this.materialRequisitionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialRequisitionFormTitle = '新增领料单'
      this.materialRequisitionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialRequisition(row.id)
        if (this.materialRequisitionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialRequisitionPage = await selectMaterialRequisitionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialRequisitionFormTitle = '修改领料单'
      this.materialRequisitionDialogVisible = true
      this.selectMaterialRequisitionInfoById(row.id)
    },
    handleReview (index, row) {
      this.materialRequisitionFormTitle = '审核领料单'
      this.materialRequisitionDialogVisible = true
      this.selectMaterialRequisitionInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.materialRequisitionFormTitle = '确认领料单'
      this.materialRequisitionDialogVisible = true
      this.selectMaterialRequisitionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialRequisitionFormTitle = '领料单详情'
      this.materialRequisitionDialogVisible = true
      this.selectMaterialRequisitionInfoById(row.id)
    },
    selectMaterialRequisitionInfoById (id) {
      selectMaterialRequisitionInfo(id).then(res => {
        this.materialRequisitionForm.id = res.id
        this.materialRequisitionForm.serialNumber = res.serialNumber
        this.materialRequisitionForm.applyDept = res.applyDept
        this.materialRequisitionForm.applyDate = res.applyDate
        this.materialRequisitionForm.reviewerResult = res.reviewerResult
        this.materialRequisitionForm.confirmerResult = res.confirmerResult
        this.materialRequisitionForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialRequisitionList(this.searchForm).then(res => {
        this.materialRequisitionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialRequisitionList(this.searchForm).then(res => {
        this.materialRequisitionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialRequisitionList(this.searchForm).then(res => {
        this.materialRequisitionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['MATERIAL_REQUISITION_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['MATERIAL_REQUISITION_CONFIRM']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    deptChange (value) {
      if (value === '生产设备部') {
        this.materialRequisitionForm.serialNumber = 'L' + moment().format('YYYYMMDD') + '-SCSB-'
      } else if (value === '质量部') {
        this.materialRequisitionForm.serialNumber = 'L' + moment().format('YYYYMMDD') + '-ZL-'
      } else if (value === '采购部') {
        this.materialRequisitionForm.serialNumber = 'L' + moment().format('YYYYMMDD') + '-CG-'
      } else if (value === '仓储部') {
        this.materialRequisitionForm.serialNumber = 'L' + moment().format('YYYYMMDD') + '-CC-'
      } else if (value === '销售部') {
        this.materialRequisitionForm.serialNumber = 'L' + moment().format('YYYYMMDD') + '-XS-'
      } else {
        this.materialRequisitionForm.serialNumber = ''
      }
    },
    materialPurchaseStockChange (row) {
      if (row.materialNo) {
        let materialPurchaseStock = this.materialPurchaseStockList.find(item => item.materialNo === row.materialNo)
        row.materialName = materialPurchaseStock.productName
        row.spec = materialPurchaseStock.spec
      } else {
        row.materialName = ''
        row.spec = ''
      }
    }
  }
}
</script>

<style>
</style>
